export interface Experiments {
  isWidgetPhaseOnePointFiveEnabled?: boolean;
  isWidgetAdditionalPaddingEnabled?: boolean;
  isWidgetPaddingImprovementEnabled?: boolean;
  isMultiParticipantStepEnabled?: boolean;
  isEnforceIndividualAsPremiumEnabled?: boolean;
  isMoreInfoLabelEnabled?: boolean;
  isFullWidthPaddingEnabled?: boolean;
  isCategoryLayoutImprovementMobileEnabled?: boolean;
  isCategoryLayoutImprovementDesktopEnabled?: boolean;
  isServiceListPageCategoryDeepLinkEnabled?: boolean;
  isServiceListPageCategoryUpdateUrlEnabled?: boolean;
}

export const experimentsSpecMap = {
  isWidgetPhaseOnePointFiveEnabled: 'specs.bookings.WidgetPhase1_5',
  isWidgetAdditionalPaddingEnabled: 'specs.bookings.WidgetAdditionalPadding',
  isWidgetPaddingImprovementEnabled: 'specs.bookings.WidgetPaddingImprovement',
  isMultiParticipantStepEnabled: 'specs.bookings.MultiParticipantStep',
  isEnforceIndividualAsPremiumEnabled:
    'specs.bookings.EnforceIndividualAsPremium',
  isMoreInfoLabelEnabled: 'specs.bookings.MoreInfoLabel',
  isFullWidthPaddingEnabled: 'specs.bookings.WidgetFullWidthPadding',
  isCategoryLayoutImprovementMobileEnabled:
    'specs.bookings.CategoryLayoutImprovementMobile',
  isCategoryLayoutImprovementDesktopEnabled:
    'specs.bookings.CategoryLayoutImprovementDesktop',
  isServiceListPageCategoryDeepLinkEnabled:
    'specs.bookings.ServiceListPageCategoryDeepLink',
  isServiceListPageCategoryUpdateUrlEnabled:
    'specs.bookings.ServiceListPageCategoryUpdateUrl',
};

function isExperimentsTrue(experimentValue) {
  return experimentValue === 'true' || experimentValue === 'new';
}

export function mapExperiments(experiments: any): Experiments {
  const mappedExperiments: Experiments = {};

  Object.entries(experimentsSpecMap).forEach((entry) => {
    const experimentEnablerName = entry[0];
    const experimentSpec = entry[1];
    mappedExperiments[experimentEnablerName] =
      experiments && experiments[experimentSpec]
        ? isExperimentsTrue(experiments[experimentSpec])
        : false;
  });

  return mappedExperiments;
}
